<!-- 用户编辑弹窗 -->
<template>
  <el-dialog
    :title="isUpdate?'修改配置':'添加配置'"
    :visible="visible"
    width="50%"
    :destroy-on-close="true"
    :lock-scroll="false"
    custom-class="ele-dialog-form"
    @update:visible="updateVisible">
    <el-form :model="ruleForm"  :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <!--      等级-->
      <el-form-item label="模块" prop="module_code">
        <el-col :span="18">
          <el-select style="width: 100%;" :span="24"  v-model="ruleForm.module_code" placeholder="请选择模块">
            <el-option :span="18"
                       v-for="item in modules"
                       :label="item.title" :value="item.code"></el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <!--分数-->
      <el-form-item label="等级" prop="level_id">
        <el-col :span="18">
          <el-select style="width: 100%;" :span="24"  v-model="ruleForm.level_id" placeholder="请选择等级">
            <el-option :span="24"
                       v-for="item in roleList"
                       :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="正确率最小值" prop="rate_min">
        <el-col :span="18">
          <el-input v-model.number="ruleForm.rate_min" placeholder="请输入正确率最小值"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="正确率最大值" prop="rate_max">
        <el-col :span="18">
          <el-input v-model.number="ruleForm.rate_max" placeholder="请输入正确率最大值"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="推题数量" prop="push_num">
        <el-col :span="18">
          <el-input placeholder="请输入推题数量" v-model="ruleForm.push_num"></el-input>
        </el-col>
      </el-form-item>

      <!--提交      -->


    </el-form>
    <div slot="footer">
      <el-button
        @click="resetForm">取消
      </el-button>
      <el-button
        type="primary"
        @click="save"
        :loading="loading">保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 父组件方法，关闭弹框
    openEdit: Function
  },
  data() {
    return {
      // 表单数据
      init: {},
      // 表单验证规则domains
      ruleForm: Object.assign({},this.data),
      // 等级下拉数据
      gradeSelection: [],
      rules: {
        module_code: [
          {required: true, message: '请选择模块', trigger: 'blur'}
        ],
        level_id: [
          {required: true, message: '请选择等级', trigger: 'blur'}
        ],
        rate_max: [
          {required: true, message: '请输入最大值',trigger: 'blur'}
        ],
        rate_min: [
          {required: true, message: '请输入最小值',trigger: 'blur'}
        ],
        push_num: [
          {required: true, message: '请输入抽题数量',trigger: 'blur'}
        ],
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 角色列表
      roleList: [],
      modules:[]

    }
  },
  mounted() {
    this.queryRoles();  // 查询角色列表
    this.moduleList()
  },
  watch: {
    data() {
      if (this.data) {
        //console.log(this.data)
        this.ruleForm = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.ruleForm = {};
        this.isUpdate = false;
      }
    }
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          //console.log(this.ruleForm)
          this.loading = true;
          this.$http[ 'post']('/Admin/pushset', this.ruleForm).then(res => {
            //console.log(res)
            this.loading = false;
            if (res.data.code === 200) {
              this.$message({type: 'success', message: res.data.msg});
              if (!this.isUpdate) {
                this.form = {};
              }
              this.updateVisible(false);
              this.$emit('done');
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    // 关闭
    resetForm(ruleForm){
      // Object.assign(this.$data.ruleForm,this.$options.data().ruleForm)
      // this.$emit('update:visible', false);
      // 调用父组件方法 隐藏弹框
      this.openEdit(null)
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },
    /* 查询等级列表 */
    queryRoles() {
      this.$http.get('/Admin/level').then(res => {

        if (res.data.code === 200) {
          this.roleList = res.data.data;
          //console.log(this.roleList)
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
    },
    moduleList() {
      this.$http.get('/Admin/module').then(res => {

        if (res.data.code === 200) {
          this.modules = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
    }
  }
}
</script>

<style scoped>
</style>
